import React from "react";
import { Box, Typography, IconButton, Container } from "@mui/material";
import './footer.css';
import { Link } from "react-router-dom";
import navItems from "../../components/utils/nav_items";
import socialMediaIcons from "../../components/utils/social_medias";
import Logo from '../../assets/byd_logo.png';
function FooterBar() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Container
      sx={{
        // backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        alignItems: "center",
        padding: { xs: "20px", sm: "80px 60px 60px" }
      }}
    >
      <Box
        sx={{
          // backgroundColor: "red",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          mb: { xs: "20px", sm: "30px" },
        }}
        >
        {/* Logo section */}
        <Box
          sx={{
            // border: "1px solid black",
            // borderRadius: "50%",
            height: "70px",
            width: "80px",
            // backgroundColor: "skyblue",
            mb:{xs:"20px",sm:"0px"},
            backgroundImage: `url(${Logo})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        >
        </Box>

        {/* Quick links */}
        <Box
          sx={{
            // backgroundColor: "yellow",
            display: "flex",
            flexDirection: {xs:"column",sm:"row"},
            fontSize:{xs:"12px",sm:"16px"},
            gap: {sm:"20px"},
            color:"#8f6B29"
          }}
        >
          {
            navItems.map((data,index)=>(
            
              <Typography key={index} textAlign="center">{data.navItem}</Typography>
            )
            )
          }
        </Box>

        {/* Social media icons */}
        <Box
          sx={{
            mt:{xs:"20px",sm:"0px"}
          }}
        >
          {socialMediaIcons.map((icon, index) => (
            <IconButton key={index} aria-label={icon.label}>
              <icon.component />
            </IconButton>
          ))}
        </Box>
      </Box>

      {/* Horizontal line */}
      <Box
        sx={{
          height: "1px",
          width: "80%",
          backgroundColor: "#000"
        }}
      ></Box>

      {/* Copyright or other footer content */}
      <Box mt={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © {year} B.Y.D Developer. All rights reserved.
        </Typography>
        <Link to="https://blackmoon.com.np/" target="_blank" >
          <Typography variant="body2" color="textSecondary" textAlign="center">
              Developed By
          </Typography>
        </Link>
      </Box>
    </Container>
  );
}

export default FooterBar;