import Prop1 from '../../assets/images/prop1.jpg';
import Prop2 from '../../assets/images/prop2.jpg';
import Prop3 from '../../assets/images/prop3.jpg';
const projects =[
    {
        id:1,
        projectsTitle:"Full Furnished 5bhk ",
        projectsLocation:"Bhaisepati , Lalitpur",
        projectsImage:Prop1,
        projectsType: "Commercial",
        projectsStatus:"ongoing"
    },
    {
        id:2,
        projectsTitle:"Commercial Land Sold",
        projectsLocation:"Ekantakuna-13 , Lalitpur",
        projectsImage:Prop2,
        projectsType:`Commercial `,
        projectsStatus:"completed",
    },{
        id:3,
        projectsTitle:"10 store projects",
        projectsLocation:"Baneshwor , Kathmandu",
        projectsImage:Prop3,
        projectsType:`Residential`,
        projectsStatus:"completed",
    },{
        id:1,
        projectsTitle:"Full Furnished 5bhk ",
        projectsLocation:"Bhaisepati , Lalitpur",
        projectsImage:Prop1,
        projectsType: "Commercial",
        projectsStatus:"ongoing"
    },{
        id:2,
        projectsTitle:"Commercial Land Sold",
        projectsLocation:"Ekantakuna-13 , Lalitpur",
        projectsImage:Prop2,
        projectsType:`Commercial `,
        projectsStatus:"completed",
    }
]
export default projects;