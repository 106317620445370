import React from 'react';
import './App.css';
import './global.css';
import RootLayout from './root_layout';
import LandingPage from './pages/landing.page';
import DesPage from './components/propertyDescription/propertyDetail';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  RouterProvider, 
  Route } from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route exact path='/' element={<RootLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="/description/:id" element={<DesPage />} />
        </Route>
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;