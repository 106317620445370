const Services =[
    {
        id:1,
        serviceTitle:"Construction",
        serviceDescription:`We bring over 20 years of experience in 
            delivering high-quality construction projects. Our mission 
            is to turn your vision into reality by using modern technology, 
            skilled professionals, and sustainable practices. We specialize 
            in residential and commercial construction, serving clients across`,
        serviceOfferedUnder: [
            "Residential Construction",
            "Commercial Construction",
            "Design and Build",
            "Project Management",
            "Renovations and Remodeling",
            "Green Building & Sustainable Construction"
        ]
    },
    {
        id:2,
        serviceTitle:"Real Estate",
        serviceDescription:`We are dedicated to providing exceptional real estate 
                            services tailored to your unique needs. Whether you're 
                            buying, selling, or renting, our team of experienced 
                            professionals is here to guide you every step of the way.`,
        serviceOfferedUnder: [
            "Residential Real Estate",
            "Commercial Real Estate",
            "Industrial Real Estate",
            "Land",
        ]
    },{
        id:3,
        serviceTitle:"Architect",
        serviceDescription:`We blend creativity with functionality to create 
            stunning architectural designs that meet your unique needs. 
            From residential homes to commercial complexes, our team of experienced 
            architects is dedicated to bringing your vision to life with precision 
            and excellence. `,
        serviceOfferedUnder: [
            "Residential Architecture",
            "Commercial Architecture",
            "Interior Design",
            "Urban Planning",
            "Sustainable Design",
            "Renovation & Restoration"
        ]
    },{
        id:4,
        serviceTitle:"Renovation",
        serviceDescription:`With a passion for design and a dedication to quality, 
            we offer comprehensive renovation services that breathe new life into 
            your spaces. Our mission is to create beautiful, functional environments 
            that enhance your everyday living and working experiences.`,
        serviceOfferedUnder: [
            "Kitchen Renovations",
            "Bathroom Renovations",
            "Home Additions",
            "Basement Finishing",
            "Exterior Renovations",
            "Commercial Renovations"
        ]
    },
]
export default Services;